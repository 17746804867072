// extracted by mini-css-extract-plugin
export var actions = "UserInvitations__actions__DmkB9";
export var checkbox = "UserInvitations__checkbox__JbHsf";
export var column = "UserInvitations__column__UTUxA";
export var company = "UserInvitations__company__oskRi";
export var container = "UserInvitations__container__YsZHC";
export var contentWrapper = "UserInvitations__contentWrapper__RXo36";
export var controlRightAlign = "UserInvitations__controlRightAlign__qxa42";
export var dataRow = "UserInvitations__dataRow__sdVbJ";
export var icon = "UserInvitations__icon__LdtmA";
export var id = "UserInvitations__id__PjjCC";
export var invitationDataview = "UserInvitations__invitationDataview__Kdhw6";
export var invitationDataviewHeader = "UserInvitations__invitationDataviewHeader__iaSQZ";
export var noRecordCard = "UserInvitations__noRecordCard____M8U";
export var paginationBar = "UserInvitations__paginationBar__qI95n";
export var paginationInfo = "UserInvitations__paginationInfo__gHNLZ";
export var pointer = "UserInvitations__pointer__Jel3k";
export var product = "UserInvitations__product__tfnuQ";
export var provider = "UserInvitations__provider__PxRnC";
export var row = "UserInvitations__row__X2Du8";
export var status = "UserInvitations__status__IDgZk";
export var tableHeaderCell = "UserInvitations__tableHeaderCell__g6MSH";
export var title = "UserInvitations__title__GfUYA";
export var titleMain = "UserInvitations__titleMain__h4OJ0";
export var user = "UserInvitations__user__wpQbg";